import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import * as styles from './about.module.css'

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const markdownRemark = data.markdownRemark
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="About" />
        <br></br>
        <article className={styles.articleDiv}>
          <section dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        </article>
      </Layout>
    )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`